#samples-dashboard {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; }
  #samples-dashboard .withSidePadding {
    width: 100%; }
  #samples-dashboard h1 {
    text-align: left;
    margin-top: 72px;
    font-size: 32pt;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: capitalize; }
  #samples-dashboard table {
    box-sizing: border-box;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 4px;
    text-align: left;
    margin: 10px 0px; }
    #samples-dashboard table thead tr th {
      padding-bottom: 10px; }
    #samples-dashboard table tbody tr td {
      padding-top: 10px; }
  #samples-dashboard a {
    color: white; }

#background-container {
  position: static;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

#background {
  position: absolute;
  top: 0;
  background-color: #000;
  background-image: linear-gradient(#333, #000);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  z-index: 1; }

#background-layer-1 {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-image: url("/images/db1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left; }
